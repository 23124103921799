import { User } from '@auth0/auth0-spa-js';
import { createContext } from 'react';

export interface UserInterface {
  user?: User,
  token?: string,
  userPerms?: string[]
}

export default createContext<UserInterface | undefined>(undefined);
