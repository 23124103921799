import React, {ReactNode} from 'react';
import {useParams} from "react-router";
import Typography from "@mui/material/Typography";
import {Dictionary} from "../../types";
import Box from "@mui/material/Box";

function tokenReplace(str: string, replacements: Dictionary<string>) {
    for (const token in replacements) {
        // Escape special regex characters
        const withLead = `:${token}`
        const escapedToken = withLead.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        // Create a global regex from token
        const regex = new RegExp(escapedToken, 'g');
        str = str.replace(regex, replacements[token]);
    }
    return str;
}

export const Title = (props: { titlePattern: string, endAdornment?: ReactNode, beginAdornment?: ReactNode }): React.JSX.Element => {
    const params: Dictionary<string> = useParams() as Dictionary<string>;
    const title = typeof params !== "undefined" ? tokenReplace(props.titlePattern, params) : props.titlePattern;
    return (
        <Box style={{display: "flex"}}>
            { props.beginAdornment ?? null }
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className='contrast'
                sx={{ flexGrow: 1 }}
            >
                {title}
            </Typography>
            { props.endAdornment ?? null }
        </Box>
    )
}