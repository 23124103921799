import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
if (!process.env.REACT_APP_AUTH0_DOMAIN || 
    !process.env.REACT_APP_AUTH0_CLIENT_ID || 
    !process.env.REACT_APP_AUTH0_CLIENT_SECRET) {
  root.render(
    <React.StrictMode>
      <p>Invalid Auth0 credentials.</p>
    </React.StrictMode>
  );
}
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : ''}
      authorizationParams={{
          redirect_uri: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`,
          connection: `${process.env.REACT_APP_AUTH0_CONNECTION}`,
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      }}
              >
      <App />
    </Auth0Provider> 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
