import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import AuthenticatedAppContext from "./AuthenticatedAppContext";
import { useQuery } from "@tanstack/react-query";

export const AuthenticatedApp = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const {isLoading, error, isAuthenticated, getAccessTokenSilently, loginWithRedirect, user} = useAuth0();
    const [token, setToken] = useState('');
    const [userPerms, setUserPerms] = useState<string[]>([]);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
        
    }, [isLoading, isAuthenticated, loginWithRedirect])
   
    useEffect(() => {
        if (token) {
            localStorage.setItem('hub_user', token);
            const tokenParts = token.split('.');
            if (tokenParts.length < 2) {
                console.warn("Invalid user token provided!");
                loginWithRedirect();
            }
            const userInfoPart = tokenParts[1];
            const decoded = atob(userInfoPart);
            const userPerms = JSON.parse(decoded);
            setUserPerms(userPerms['permissions'])
        }
    }, [token, loginWithRedirect, setUserPerms])
   
    const { isLoading: appIsLoading } = useQuery({
        queryKey: ['user-token'],
        queryFn: async (): Promise<any> => {
            const token =  await getAccessTokenSilently();
            setToken(token);
            return token;
        },
    });

 
    const value = useMemo(() => ({
        user,
        token,
        userPerms
      }), [
        user,
        token,
        userPerms
      ]);


    if (!userPerms.length || !userPerms.includes("superuser")) {
        return <p>You do not have permission to view this app.</p>
    }

    if (error) {
        return (
            <p>{error.message}</p>
        )
    }

    if (isLoading || appIsLoading) {
        return(
            <CircularProgress color="primary"></CircularProgress>
        )
    }
    
    if (!appIsLoading) {
        return (
            <AuthenticatedAppContext.Provider value={value}>
                {children}
            </AuthenticatedAppContext.Provider>
        )
    }
    return (
        <p>Something went wrong</p>
    )
  
}