import React, {useCallback, useContext} from 'react';
import {useQuery} from "@tanstack/react-query";
import {CircularProgress} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {lightBlue, lightGreen, grey} from "@mui/material/colors";
import { authenticatedRequest } from '../authenticate/authenticatedRequest';
import {ChangeCircle} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {UpdateImage} from "../images";
import {PromoteActiveCanaryImage} from "../images/images";
import AuthenticatedAppContext from "../authenticate/AuthenticatedAppContext";

export type ServiceName = "blue" | "green";

export const CanaryService = (): React.JSX.Element => {
    const { isLoading, data } = useQuery<ServiceName>({
        queryKey: ['active-service'],
        queryFn: async (): Promise<ServiceName> => {
            return await authenticatedRequest(`${process.env.REACT_APP_PROXY_SERVER}/api/services`).then(s => s.json());
        },
    });

    if(isLoading) {
        return (<CircularProgress />)
    }

    return <Service service={data} />
}


export const ChangeService = (): React.JSX.Element => {
    const { isLoading, data } = useQuery<ServiceName>({
        queryKey: ['active-service'],
        queryFn: async (): Promise<ServiceName> => {
            return await authenticatedRequest(`${process.env.REACT_APP_PROXY_SERVER}/api/services`).then(s => s.json());
        },
    });


    // Changes the active canary service. This does not update the underlying code; simply changes which service will
    // receive the code updates.
    const changeCanaryService = async() => {
        let selector = '';
        switch (data) {
            case 'blue':
                selector = 'green';
                break;
            case 'green':
                selector = 'blue';
                break;
            default:
                selector = 'green';
                break;
        }
        const changeCanaryService = await authenticatedRequest(
            `${process.env.REACT_APP_PROXY_SERVER}/api/services`,
            {
                method: "post",
                body: JSON.stringify({
                    selector: selector,
                }),
            }
        );
        if (!changeCanaryService.ok) throw changeCanaryService;

        return changeCanaryService;
    };

    if(isLoading) {
        return (<CircularProgress />)
    }

    return (
        <IconButton onClick={async () => {
            const res = await changeCanaryService();
        }}>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <ChangeCircle />
                <Typography variant={'body2'}>
                    Change Canary Service
                </Typography>
            </div>
        </IconButton>
    )
};



/**
 * ProductionService returns an element representing the active production service (e.g. not the active canary service.)
 */
export const ProductionService = (): React.JSX.Element => {
    const { isLoading, data } = useQuery<ServiceName>({
        queryKey: ['active-service'],
        queryFn: async (): Promise<ServiceName> => {
            return await authenticatedRequest(`${process.env.REACT_APP_PROXY_SERVER}/api/services`).then(s => s.json());
        },
    });

    if(isLoading) {
        return (<CircularProgress />)
    }
    switch (data) {
        case 'blue':
            return <Service service={'green'} />
        case 'green':
            return <Service service={'blue'} />
        default: 
            return <Service service={'green'} />
    }
}

const color = (serviceName = ""): string => {
    switch(serviceName) {
        case "blue":
            return lightBlue.A700;
        case "green":
            return lightGreen.A700;
        default:
            return grey.A700;
    }
}

export const Service = (props: { service?: ServiceName }): React.JSX.Element =>  {
    const displayColor = color(props.service);
    return (
        <Tooltip title={`Current service is: ${props.service}`}>
            <div style={{ width: "24px", height: "24px", backgroundColor: displayColor, borderRadius: "50%"}} />
        </Tooltip>
    );
}