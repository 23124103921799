import React from "react";
import {Link, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";


export const HostnamesAsLinks = ({hostnames}: { hostnames: string[] }): React.JSX.Element => {
    const theme = useTheme();
    return (
        <Box>
            {hostnames.map(h => (
                <Box key={h} display="flex">
                    <Link href={`https://${h}`} target="_blank" rel="noopener">
                        {h}
                    </Link>
                    <Link sx={{pt: "4px", pl: "4px", ":hover": {color: theme.palette.grey[700]}}} href={`https://${h}`}
                          target="_blank" rel="noopener">
                        <ExitToAppIcon sx={{fontSize: 14, color: theme.palette.grey[500]}}/>
                    </Link>
                </Box>
            ))}
        </Box>
    )
}