type Payload = {
  method: string,
  body: string,
}

export const authenticatedRequest = async (endpoint: string, payload?: Payload) => {
  const cookieName = `hub_user`;

  const token = localStorage.getItem(cookieName);
  if (!token) {
    const response = new Response(null, {
      status: 400,
      statusText: `No authentication token provided.`
    });
    
    return response;
  }
  
  return await fetch(`${endpoint}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: payload ? payload.method : 'get',
    body: payload ? payload.body : null,
  });
}