import React from "react";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueueIcon from '@mui/icons-material/ClearAll';
import SitesIcon from '@mui/icons-material/LaptopMac';
import {Link} from "@mui/material";

export const Nav = (): React.JSX.Element => {
    return (
        <React.Fragment>
            <ListItemButton component={Link} href="/">
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton component={Link} href="/sites">
                <ListItemIcon>
                    <SitesIcon />
                </ListItemIcon>
                <ListItemText primary="Sites" />
            </ListItemButton>
        </React.Fragment>
    )
}