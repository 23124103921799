import { createTheme } from '@mui/material';
import {LinkProps} from "@mui/material";
import {LinkBehavior} from "./components/link";
export const Theme = createTheme({
  components: {
    MuiTypography: {
        styleOverrides: {
            h1: {
                '&.contrast': {
                    color: '#ffffff'
                }
            },
            h6: {
                '&.contrast': {
                    color: '#ffffff'
                }
            },
        },
    },
    MuiFab: {
        styleOverrides: {
            primary: {
                '&.contrast': {
                    color: '#ffffff'
                }
            }
        }
    },
    MuiButton: {
        styleOverrides: {
            contained: {
                '&.contrast': {
                    color: '#ffffff'
                }
            },
        },
        defaultProps: {
            color: 'secondary'
        }
    },
    MuiLink: {
        defaultProps: {
            component: LinkBehavior,
        } as LinkProps,
    },
  },
  palette: {
      primary: {
          main: '#f69520',
      },
      secondary: {
          main: '#ffffff',
      },
  },
});