import React, {useContext} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {Nodes} from "../nodes";
import {PodsByService} from "../pods";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';
import { CanariedImage, UpdateImage } from '../images';
import { PromoteActiveCanaryImage } from '../images/images';
import AuthenticatedAppContext from "../authenticate/AuthenticatedAppContext";

export const UpdatedAt = (props: { time: string }): React.JSX.Element => {
    const date = new Date();
    date.setTime(parseInt(props.time, 10));
    const theme = useTheme();

    return (
        <Typography color={theme.palette.grey["700"]} variant="body2">Last updated {date.toISOString()}</Typography>
    )
}

export function Dashboard() {
    const {isLoading, user, error, isAuthenticated, loginWithRedirect} = useAuth0();
    const authenticatedContext = useContext(AuthenticatedAppContext);
    const theme = useTheme();
    if (isLoading) {
        return (
            <CircularProgress color="primary"></CircularProgress>
        )
    }
    if (error) {
        return (
            <p>{error.message}</p>
        )
    }

    if (!isAuthenticated || user === undefined) {
        loginWithRedirect();
    }

    const userPerms = authenticatedContext?.userPerms;

    document.cookie = `hub_user=${user?.token}`;

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={5}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            maxHeight: '600'
                        }}
                    >
                        <PodsByService />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >

                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: '600'
                            }}
                        >
                            <CanariedImage />
                            <Typography color={theme.palette.grey["700"]} variant="body2">
                                The above image is actively canaried to the specified canary service. Any sites on the canary service will
                                have this version of code applied to them.
                            </Typography>
                        </Paper>
                        <hr />
                        { userPerms?.includes('deployment:admin') && (
                           <>
                               <UpdateImage />
                               <PromoteActiveCanaryImage />
                           </>
                        )}

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}