import React from 'react';
import {createBrowserRouter, redirect, RouterProvider} from 'react-router-dom';
import {Dashboard} from "./components/dashboard";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {updateSite, createSite, site, SiteConfigPage} from "./components/site/site";
import {Ingresses, ingresses} from "./components/site/ingresses";
import {Theme} from "./theme";
import { ProtectedRoute } from './components/authenticate/protectedRoute';
import { AuthenticatedApp } from './components/authenticate/AuthenticatedApp';
import {images, promoteServiceImage, updateServiceImage} from './components/images/images';

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <ProtectedRoute component={Dashboard} titlePattern="Dashboard"></ProtectedRoute>,
            loader: images,
            action: updateServiceImage
        },
        {
            path: "/sites",
            element: <ProtectedRoute component={Ingresses} titlePattern="Active Websites"></ProtectedRoute>,
            loader: ingresses,
        },
        {
            path: "/sites/create",
            element: <ProtectedRoute component={SiteConfigPage} titlePattern="Create Website"></ProtectedRoute>,
            loader: site,
            action: createSite
        },
        {
            path: "/sites/:id",
            element: <ProtectedRoute component={SiteConfigPage} titlePattern="Site :id"></ProtectedRoute>,
            loader: site,
            action: updateSite,
        },
    ]
);

function App() {
    return (
        <QueryClientProvider client={new QueryClient()}>
                <AuthenticatedApp>
                    <ThemeProvider theme={Theme}>
                        <CssBaseline/>
                        <RouterProvider router={router}/>
                    </ThemeProvider>
                </AuthenticatedApp>
        </QueryClientProvider>
    );
}

export default App;
