import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import React from "react";

export const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
    >((props, ref) => {
    const { href, state, ...other } = props;
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} state={state} {...other} />;
});