import React  from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useWebSocket from "react-use-websocket";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {UpdatedAt} from "../dashboard/dashboard";
import { CircularProgress } from '@mui/material';

export const PodsByService = (): React.JSX.Element => {
    const { lastJsonMessage } = useWebSocket<{ blue: number, green: number, timestamp: string } | null>(`${process.env.REACT_APP_PROXY_WEB_SOCKET}/metrics/pods`);
    return (
        <Box height={400} width="100%">
            <h4 style={{ marginTop: 0, marginBottom: "1rem" }}>
                Deployment Status
            </h4>
            {!lastJsonMessage ? <CircularProgress color="primary"></CircularProgress> :
            <ResponsiveContainer width="100%" height="100%" maxHeight={350}>
                <BarChart
                    width={300}
                    data={[{
                        name: 'Pods by Service',
                        blue: lastJsonMessage.blue,
                        green: lastJsonMessage.green,
                    }]}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="blue" fill="#2a72e8" />
                    <Bar dataKey="green" fill="#0ba352" />
                </BarChart>
            </ResponsiveContainer>}
            {lastJsonMessage ? <UpdatedAt time={lastJsonMessage.timestamp} /> : null}
        </Box>
    );
}